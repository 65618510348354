const ResolutionList = [
  { value: '720x480', name: '720 x 480' },
  { value: '720x486', name: '720 x 486' },
  { value: '1280x720', name: '1280 x 720' },
  { value: '1920x1080', name: '1920 x 1080' },
  { value: '1998x1080', name: '1998 x 1080 (DCP 2K flat)' },
  { value: '2048x858', name: '2048 x 858 (DCP 2K Scope)' },
  { value: '2048x1080', name: '2048 x 1080 (DCP 2K full 1.90)' },
  { value: '3996x2160', name: '3996 x 2160 (DCP 4K flat 1.85)' },
  { value: '3840x2160', name: '3840 x 2160 (UHD)' },
  { value: '4096x1716', name: '4096 x 1716 (DCP 4K Scope 2.39)' },
  { value: '4096x2160', name: '4096 x 2160 (DCP 4K full 1.90)' },
  { value: '7680x4320', name: '7680 x 4320 (UHD 8K)' },
]

export default ResolutionList

import React, { useState } from 'react'

export default ({
  formatType,
  finalResultFolder,
  finalResultFile,
  inputSegment,
}) => {
  const typeNonEpisodicSegmented = formatType === 'nonepisodicseg'

  const [copyText, setCopyText] = useState(false)

  function copyFolderName() {
    const el = document.getElementById('resultFolderName')
    el.select()
    document.execCommand('copy')
    setCopyText(!copyText)
    el.parentElement.classList.add('copied-open')
    const timer = setTimeout(() => {
      el.parentElement.classList.remove('copied-open')
    }, 2000)
    return () => clearTimeout(timer)
  }

  function copySubFolderName() {
    const el = document.getElementById('resultSubFolderName')
    el.select()
    document.execCommand('copy')
    setCopyText(!copyText)
    el.parentElement.classList.add('copied-open')
    const timer = setTimeout(() => {
      el.parentElement.classList.remove('copied-open')
    }, 2000)
    return () => clearTimeout(timer)
  }

  function copyFileName() {
    const el = document.getElementById('resultFileName')
    el.select()
    document.execCommand('copy')
    setCopyText(!copyText)
    el.parentElement.classList.add('copied-open')
    const timer = setTimeout(() => {
      el.parentElement.classList.remove('copied-open')
    }, 2000)
    return () => clearTimeout(timer)
  }

  return (
    <div className="container">
      <div className="result">
        <div className="result__item">
          <h3 className="result__head">Folder Name</h3>
          <div className="result__content">
            <input
              id="resultFolderName"
              type="text"
              placeholder={(() => {
                switch (formatType) {
                  case 'episodic':
                    return 'i.e. zombie_apocalypse_s01e101_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160'
                  case 'nonepisodiclp':
                    return 'i.e. zombie_apocalypse_lp_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160'
                  case 'nonepisodicseg':
                    return 'i.e. zombie_apocalypse_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160'
                  default:
                    return 'i.e. zombie_apocalypse_s01e101_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160'
                }
              })()}
              value={finalResultFolder}
              readOnly
            />
            <button onClick={copyFolderName}>Copy</button>
          </div>
        </div>

        {typeNonEpisodicSegmented && (
          <div className="result__item">
            <h3 className="result__head">Sub-Folder Name</h3>
            <div className="result__content">
              <input
                id="resultSubFolderName"
                type="text"
                placeholder="i.e. r01"
                value={inputSegment}
                readOnly
              />
              <button onClick={copySubFolderName}>Copy</button>
            </div>
          </div>
        )}

        <div className="result__item">
          <h3 className="result__head">File Name</h3>
          <div className="result__content">
            <input
              id="resultFileName"
              type="text"
              placeholder={(() => {
                switch (formatType) {
                  case 'episodic':
                    return 'i.e. zombie_apocalypse_s01e101_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160.#######.tif'
                  case 'nonepisodiclp':
                    return 'i.e. zombie_apocalypse_lp_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160.#######.tif'
                  case 'nonepisodicseg':
                    return 'i.e. zombie_apocalypse_r01_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160.#######.tif'
                  default:
                    return 'i.e. zombie_apocalypse_s01e101_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160.#######.tif'
                }
              })()}
              value={finalResultFile}
              readOnly
            />
            <button onClick={copyFileName}>Copy</button>
          </div>
        </div>
      </div>
    </div>
  )
}

const AspectRatioList = [
  { value: '133', name: '1.33  (4:3)' },
  { value: '137', name: '1.37  (4:3 Academy)' },
  { value: '143', name: '1.43  (IMAX)' },
  { value: '166', name: '1.66' },
  { value: '178', name: '1.78  (16:9 HDTV)' },
  { value: '185', name: '1.85  (American 35mm)' },
  { value: '190', name: '1.90' },
  { value: '235', name: '2.35  (Cinemascope)' },
  { value: '239', name: '2.39  (Scope)' },
]

export default AspectRatioList

const TransferFunctionList = [
  { value: 'ap0', name: 'ACES 2065-1 (AP0)' },
  { value: 'adx', name: 'ADX' },
  { value: 'bmdlog', name: 'BMD Log' },
  { value: 'bmdlog46k', name: 'BMD Log 4.6K' },
  { value: 'canonlog2', name: 'Canon Log2' },
  { value: 'canonlog3', name: 'Canon Log3' },
  { value: 'cflog', name: 'CFLog' },
  { value: 'cineon', name: 'Cineon Log' },
  { value: 'g26', name: 'DCI (2.6 Gamma)' },
  { value: 'tlog', name: 'Filmlight T-Log' },
  { value: 'g24', name: 'ITU-R BT.1886 (2.4 Gamma)' },
  { value: 'lin', name: 'Linear (1.0 Gamma)' },
  { value: 'logc', name: 'LogCv3' },
  { value: 'vlog', name: 'Panasonic V-Log' },
  { value: 'log3g10', name: 'RedLog3G10' },
  { value: 'redlogfilm', name: 'RedLogFilm' },
  { value: 'slog2', name: 'S-Log2' },
  { value: 'slog3', name: 'S-Log3' },
  { value: 'pq', name: 'SMPTE ST-2084 (PQ)' },
  { value: 'g22', name: 'sRGB (2.2 Gamma)' },
]

export default TransferFunctionList

const ColorSpaceList = [
  { value: 'ap0', name: 'ACES 2065-1 (AP0)' },
  { value: 'adx', name: 'ADX' },
  { value: 'arriwg', name: 'ARRI Wide Gamut' },
  { value: 'bmdfilm', name: 'BMD Film' },
  { value: 'bmdfilm46k', name: 'BMD Film 4.6K' },
  { value: 'canonp3dci', name: 'Canon DCI-P3+' },
  { value: 'canoncine', name: 'CanonCineGamut' },
  { value: 'cfcolor', name: 'CFColor' },
  { value: 'cineonpd', name: 'Cineon (printing density)' },
  { value: 'p3dci', name: 'DCI P3' },
  { value: 'dragonc', name: 'DRAGONcolor' },
  { value: 'dragonc2', name: 'DRAGONcolor2' },
  { value: 'egamut', name: 'Filmlight E-Gamut' },
  { value: 'rec2020', name: 'ITU-R BT.2020 (Rec.2020)' },
  { value: 'rec709', name: 'ITU-R BT.709 (Rec.709)' },
  { value: 'p3d65', name: 'P3 D65,Panasonic VGamut' },
  { value: 'vgamut', name: 'Panasonic VGamut' },
  { value: 'redc', name: 'REDcolor' },
  { value: 'redc2', name: 'REDcolor2' },
  { value: 'redc3', name: 'REDcolor3' },
  { value: 'redc4', name: 'REDcolor4' },
  { value: 'redspace', name: 'REDspace' },
  { value: 'rwg', name: 'REDWideGamutRGB' },
  { value: 'sgamut', name: 'Sony SGamut' },
  { value: 'sgamut3', name: 'Sony SGamut3' },
  { value: 'sgamut3-cine', name: 'Sony SGamut3.cine' },
  { value: 'srgb', name: 'sRGB' },
  { value: 'xyz', name: 'XYZ' },
]

export default ColorSpaceList

import React from 'react'
// const CaptionsList = [
//   { name: 'A', value: '<p>' },
//   {
//     name: 'B',
//     value: {
//       1: 'aaa',
//       2: 'bbb',
//     },
//   },
// ]

export const projectTitleCaption =
  'The full title, using lower-case characters, substituting spaces with underscores, and omitting illegal characters.'

export const EpisodeCaption = (
  <>
    <p>
      For episodic content, an abbreviation denoting the season and episode
      number.{' '}
    </p>
    <p>
      The season number should be preceded by an “s”, the episode number should
      be preceded by an “e”. The season number should be notated in two-digits,
      the episode number should be notated in three-digits.
    </p>
    <p>(Example: season 1 episode 1 would be “s01e101”.) </p>
    <p>
      In the event the production order differs from the final viewing order,
      the episode number should reflect the production order.
    </p>
  </>
)

export const NonEpisodeCaption = (
  <>
    <p>
      For non-episodic content, an abbreviation denoting the segment/reel number
      or if the content is in ‘longplay’ format (a continuous sequence not
      broken into segments/reels).
    </p>
    <p>
      For segmented/reel-based content, the segment/reel number should be
      preceded by an “r” and the segment/reel number notated in two-digits. For
      ‘longplay’ content, the abbreviation of “lp” should be used.
    </p>
    <p>
      (Examples: reel 1 of a segmented/reel-based asset would be “r01”, a
      ‘longplay’ asset would be “lp”.)
    </p>
  </>
)

export const AssetTypeCaption = (
  <>
    An abbreviation describing the type of asset.
    <ul className="caption-lists">
      <li>
        <b>Textless Notes</b>
      </li>
      <li>
        - Textless assets should include a textless background for any
        shot/sequence of the content that contains on-screen graphic text.
      </li>
      <li>
        - Textless shots/sequences do NOT need to be provided for on-screen
        graphic text that appears over black in the texted version.
      </li>
      <li>
        - Textless shots/sequences should be provided cut-to-cut (i.e., the
        textless insert should not begin or end in the middle of a shot, the
        entire shot should be provided).
      </li>
      <li>
        - Textless shots/sequences should include (3) frame handles on both
        sides of the shot/sequence.
      </li>
      <li>
        - Textless shots/sequences should be numbered to match the numbering of
        the corresponding texted shot/sequence.
      </li>
      <li>
        - Textless main titles or credits do not require a distinct naming
        convention from other textless inserts.
      </li>
    </ul>
  </>
)

export const BitDepthCaption = (
  <>
    <p>
      An abbreviation describing the bit depth of the image files contained in
      the asset. The abbreviation should be a two-digit number followed by a
      “b”.{' '}
    </p>
    <p>(Example: 16-bit image files would be “16b”.)</p>
  </>
)

export const DynamicRangeCaption = (
  <>
    An abbreviation describing the Dynamic Range. <br />
    This abbreviation does not need to be included for the Non-Graded Archival
    Master (NAM). The abbreviation should be included for the Graded Archival
    Master (GAM) and should reflect the creatively intended dynamic range. The
    abbreviation must be included for the Video Display Master (VDM) and the
    Digital Cinema Delivery Master (DCDM).
  </>
)

export const ACESVerCaption = (
  <>
    <p>
      An abbreviation detailing the version of ACES used (when applicable).{' '}
      <br />
      The version number should be detailed in three-digits, out to two decimal
      point places. The notation of the ACES version number should not include a
      decimal point itself.{' '}
    </p>
    <p>(Example: ACES 1.1 would be “aces110”, ACES 1.03 would be “aces103”)</p>
  </>
)

export const ColorSpaceCaption = (
  <>
    <p>
      An abbreviation describing the Color Space of the image. <br />
      For Non-Graded Archival Masters and Graded Archival Masters, the
      abbreviation should reference the color space of the file it is
      describing, not the intended ‘final’ display color space.{' '}
    </p>
    <p>
      (Example: If a standard-dynamic-range project was graded in the camera’s
      Log color space, the abbreviation should reference that scene-referred
      color space, not ITU-R BT.709 / Rec.709 color space.)
    </p>
  </>
)

export const TransferFunctionCaption = (
  <>
    <p>An abbreviation describing the Transfer Function of the image.</p>
    <p>
      For Non-Graded Archival Masters and Graded Archival Masters, the
      abbreviation should reference the transfer function of the file it is
      describing, not the intended transfer function of the ‘final’ display.{' '}
    </p>
    <p>
      (Example: If a standard-dynamic-range project was graded in the camera’s
      Log color space, the abbreviation should reference the corresponding
      transfer function not ITU-R BT.1886 / 2.4 Gamma.)
    </p>
  </>
)

export const DateCaption = (
  <>
    <p>The date the asset was created in year/month/day format</p>
    <p>(Example: “20190102” for January 2nd, 2019).</p>
  </>
)

export const AspectRatioCaption = (
  <>
    <p>
      The aspect ratio of the active image, derived by dividing the active
      resolution of the width by the active resolution of the height, rounded to
      two decimal points. The notation of the aspect ratio should not include a
      decimal point places
    </p>
    <p>
      (Example: 1.78 aspect ratio would be notated as “178”). The aspect ratio
      code should not be included on un-matted Non-Graded Archival Master (NAM)
      or Graded Archival Master (GAM) assets. The aspect ratio code must be
      included on the Video Display Master (VDM) and Digital Cinema Delivery
      Master (DCDM) assets.
    </p>
  </>
)

export const ResolutionCaption =
  'The resolution of the image notated as [width]x[height]. The resolution should detail the full resolution of the file, not just the active image.'

export const FrameNumberCaption = (
  <>
    <p className="color-em">
      A seven-digit number signifying the Frame Number.
    </p>{' '}
    <p>
      If the Frame Number is less than seven-digits, pad out to seven-digits
      with zeros (Example: 0086400).{' '}
    </p>
    <p>
      The image sequence must be sequential in numbering and numbered in the
      order they are intended to be displayed in. The numbering should be
      consistent between asset types (Example: the first-frame-of-action for a
      Graded Archival Master and a Video Display Master of the same content
      should be numbered the same).
    </p>
  </>
)

export const FileExtensionCaption =
  'A group of letters and or numbers after a period in a file name, denoting the format of the file.'

import React from 'react'

export default function Disclaimer() {
  return (
    <>
      <div className="disclaimer">
        <b>*1. Legal and Illegal Characters</b>
        <br />
        Legal characters: [ a-z ], [ A-Z ], [ 0-9 ], [ . ], [ _ ], [ - ]
        <br />
        ILLEGAL: spaces or any other symbols/characters.
      </div>
    </>
  )
}

import React, { useState } from 'react'

interface Props {
  captionText: string
  captionClass: string
}

export default ({ captionText, captionClass }: Props) => {
  const [caption, setCaption] = useState(false)

  return (
    <div className="caption">
      <button
        className="caption__button"
        onMouseEnter={() => setCaption(true)}
        onMouseLeave={() => setCaption(false)}
      >
        ?
      </button>
      {caption && (
        <div className={`caption__content ${captionClass}`}>{captionText}</div>
      )}
    </div>
  )
}

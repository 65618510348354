import React, { useState } from 'react'

export default () => {
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const validError = email === '' || message === ''

  return (
    <>
      <form
        name="request"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        // data-netlify-recaptcha="true"
      >
        <input type="hidden" name="form-name" value="request" />
        <div className="fields">
          <div className="field">
            <textarea
              id="request-message"
              name="message"
              rows="4"
              placeholder="Request message"
              onChange={e => setMessage(e.target.value)}
              value={message}
            ></textarea>
          </div>
          <div className="field">
            <input
              id="request-email"
              type="email"
              name="email"
              placeholder="Email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          </div>
        </div>
        {/* <div data-netlify-recaptcha="true"></div> */}
        <div className="actions">
          <button
            className=""
            role="button"
            aria-label="Send"
            type="submit"
            disabled={validError && true}
          >
            Send
          </button>
        </div>
      </form>
    </>
  )
}

import React, { ChangeEvent, useState, useEffect, ReactNode } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import Layout from '../components/Layout'
import Panel from '../components/Panel'
import Form from '../components/Form'
import Disclaimer from '../components/Disclaimer'

interface Props {
  location: ReactNode
}
const Convention = ({ location }: Props) => {
  const type = 'NETFLIX'
  const pageTitle = 'NETFLIX Naming Convention Generator'
  const pageSlug = 'convention'

  const type1 = 'Episodic'
  const type2 = 'Non-Episodic Longplay'
  const type3 = 'Non-Episodic Segmented/Reel-Based'

  // Clear localstorage
  if (typeof window !== 'undefined') {
    window.localStorage.clear()
  }

  // All items state
  const [inputProjectTitle, setInputProjectTitle] = useState('')
  const [inputSeasonEpisode, setInputSeasonEpisode] = useState('')
  const [inputSegment, setInputSegment] = useState('')
  const [selectAssetType, setSelectAssetType] = useState('')
  const [selectBitDepth, setSelectBitDepth] = useState('')
  const [selectDynamicRange, setSelectDynamicRange] = useState('')
  const [selectACESVer, setSelectACESVer] = useState('')
  const [selectColorSpace, setSelectColorSpace] = useState('')
  const [selectTransferFunc, setSelectTransferFunc] = useState('')
  const [inputDateYear, setInputDateYear] = useState<any>('')
  const [selectDateMM, setSelectDateMM] = useState<any>('')
  const [selectDateDD, setSelectDateDD] = useState<any>('')
  const [selectAspectRatio, setSelectAspectRatio] = useState('')
  const [inputAspectRatio, setInputAspectRatio] = useState('')
  const [selectResolution, setSelectResolution] = useState('')
  const [inputResolution, setInputResolution] = useState('')
  const [inputFrameNumber, setInputFrameNumber] = useState('')
  const [selectFileExtension, setSelectFileExtension] = useState('')
  const [inputFileExtension, setInputFileExtension] = useState('')

  // Project title onchange function
  function handleProjectTitle(e: ChangeEvent<HTMLInputElement>) {
    sessionStorage.setItem('ProjectTitle', e.target.value)
    setInputProjectTitle(e.target.value)
  }

  // SeasonEpisode onchange function
  function handleSeasonEpisode(e: ChangeEvent<HTMLInputElement>) {
    sessionStorage.setItem('SeasonEpisode', e.target.value)
    setInputSeasonEpisode(e.target.value)
  }

  // Segment onchange function
  function handleSegment(e: ChangeEvent<HTMLInputElement>) {
    sessionStorage.setItem('Segment', e.target.value)
    setInputSegment(e.target.value)
  }

  // AssetType onchange function
  function handleAssetType(e: ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem('AssetType', e.target.value)
    setSelectAssetType(e.target.value)
  }

  // BitDepth onchange function
  function handleBitDepth(e: ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem('BitDepth', e.target.value)
    setSelectBitDepth(e.target.value)
  }

  // DynamicRange onchange function
  function handleDynamicRange(e: ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem('DynamicRange', e.target.value)
    setSelectDynamicRange(e.target.value)
  }

  // ACESVer onchange function
  function handleACESVer(e: ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem('ACESVer', e.target.value)
    setSelectACESVer(e.target.value)
  }

  // ColorSpace onchange function
  function handleColorSpace(e: ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem('ColorSpace', e.target.value)
    setSelectColorSpace(e.target.value)
  }

  // TransferFunc onchange function
  function handleTransferFunc(e: ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem('TransferFunc', e.target.value)
    setSelectTransferFunc(e.target.value)
  }

  // DateYear onchange function
  function handleDateYear(e: ChangeEvent<HTMLInputElement>) {
    sessionStorage.setItem('DateYear', e.target.value)
    setInputDateYear(e.target.value)
  }
  // DateMM onchange function
  function handleDateMM(e: ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem('DateMM', e.target.value)
    setSelectDateMM(e.target.value)
  }
  // DateDD onchange function
  function handleDateDD(e: ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem('DateDD', e.target.value)
    setSelectDateDD(e.target.value)
  }

  // Aspect Ratio(select) onchange function
  function handleSelectAspectRatio(e: ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem('AspectRatioSelect', e.target.value)
    setSelectAspectRatio(e.target.value)
  }
  // Aspect Ratio(input) onchange function
  function handleInputAspectRatio(e: ChangeEvent<HTMLInputElement>) {
    sessionStorage.setItem('AspectRatioInput', e.target.value)
    setInputAspectRatio(e.target.value)
  }

  // Resolution(select) onchange function
  function handleSelectResolution(e: ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem('ResolutionSelect', e.target.value)
    setSelectResolution(e.target.value)
  }
  // Resolution(input) onchange function
  function handleInputResolution(e: ChangeEvent<HTMLInputElement>) {
    sessionStorage.setItem('ResolutionInput', e.target.value)
    setInputResolution(e.target.value)
  }

  // FrameNumber onchange function
  function handleFrameNumber(e: ChangeEvent<HTMLInputElement>) {
    sessionStorage.setItem('FrameNumber', e.target.value)
    setInputFrameNumber(e.target.value)
  }

  // FileExtension(select) onchange function
  function handleSelectFileExtension(e: ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem('FileExtensionSelect', e.target.value)
    setSelectFileExtension(e.target.value)
  }
  // FileExtension(input) onchange function
  function handleInputFileExtension(e: ChangeEvent<HTMLInputElement>) {
    sessionStorage.setItem('FileExtensionInput', e.target.value)
    setInputFileExtension(e.target.value)
  }

  return (
    <Layout location={location} pageSlug={pageSlug} pageTitle={pageTitle}>
      <div className="container page-header">
        <h1 className="page-header__title font-headline">{pageTitle}</h1>
      </div>

      <div className="intro container">
        <div className="lead">
          <p>
            This is a website for the automated creation of {type} deliverable
            naming conventions based on {type} "Preparing Picture Archival
            Assets"{' '}
            <a
              href="https://partnerhelp.netflixstudios.com/hc/en-us/articles/360000384727-Content-Hub-Preparing-Picture-Archival-Assets"
              target="_blank"
              rel="noopener noreferrer"
            >
              guidelines
            </a>
            . Naming conventions are based on guidelines published by {type} as
            of 06/01/2020.
          </p>
          <p>
            Note: None of the information you enter here will be shared with any
            third party.
          </p>
        </div>
      </div>

      <Tabs>
        <div className="tab-list__wrapper font-headline">
          <TabList>
            <Tab>{type1}</Tab>
            <Tab>{type2}</Tab>
            <Tab>{type3}</Tab>
          </TabList>
        </div>

        <TabPanel>
          <Panel
            formatType="episodic"
            formatTypeName={type1}
            inputProjectTitle={inputProjectTitle}
            handleProjectTitle={handleProjectTitle}
            inputSeasonEpisode={inputSeasonEpisode}
            handleSeasonEpisode={handleSeasonEpisode}
            inputSegment={inputSegment}
            handleSegment={handleSegment}
            selectAssetType={selectAssetType}
            handleAssetType={handleAssetType}
            selectBitDepth={selectBitDepth}
            handleBitDepth={handleBitDepth}
            selectDynamicRange={selectDynamicRange}
            handleDynamicRange={handleDynamicRange}
            selectACESVer={selectACESVer}
            handleACESVer={handleACESVer}
            selectColorSpace={selectColorSpace}
            handleColorSpace={handleColorSpace}
            selectTransferFunc={selectTransferFunc}
            handleTransferFunc={handleTransferFunc}
            inputDateYear={inputDateYear}
            setInputDateYear={setInputDateYear}
            handleDateYear={handleDateYear}
            selectDateMM={selectDateMM}
            setSelectDateMM={setSelectDateMM}
            handleDateMM={handleDateMM}
            selectDateDD={selectDateDD}
            setSelectDateDD={setSelectDateDD}
            handleDateDD={handleDateDD}
            selectAspectRatio={selectAspectRatio}
            handleSelectAspectRatio={handleSelectAspectRatio}
            inputAspectRatio={inputAspectRatio}
            handleInputAspectRatio={handleInputAspectRatio}
            selectResolution={selectResolution}
            handleSelectResolution={handleSelectResolution}
            inputResolution={inputResolution}
            handleInputResolution={handleInputResolution}
            inputFrameNumber={inputFrameNumber}
            handleFrameNumber={handleFrameNumber}
            selectFileExtension={selectFileExtension}
            handleSelectFileExtension={handleSelectFileExtension}
            inputFileExtension={inputFileExtension}
            handleInputFileExtension={handleInputFileExtension}
          />
        </TabPanel>
        <TabPanel>
          <Panel
            formatType="nonepisodiclp"
            formatTypeName={type2}
            inputProjectTitle={inputProjectTitle}
            handleProjectTitle={handleProjectTitle}
            inputSeasonEpisode={inputSeasonEpisode}
            handleSeasonEpisode={handleSeasonEpisode}
            inputSegment={inputSegment}
            handleSegment={handleSegment}
            selectAssetType={selectAssetType}
            handleAssetType={handleAssetType}
            selectBitDepth={selectBitDepth}
            handleBitDepth={handleBitDepth}
            selectDynamicRange={selectDynamicRange}
            handleDynamicRange={handleDynamicRange}
            selectACESVer={selectACESVer}
            handleACESVer={handleACESVer}
            selectColorSpace={selectColorSpace}
            handleColorSpace={handleColorSpace}
            selectTransferFunc={selectTransferFunc}
            handleTransferFunc={handleTransferFunc}
            inputDateYear={inputDateYear}
            setInputDateYear={setInputDateYear}
            handleDateYear={handleDateYear}
            selectDateMM={selectDateMM}
            setSelectDateMM={setSelectDateMM}
            handleDateMM={handleDateMM}
            selectDateDD={selectDateDD}
            setSelectDateDD={setSelectDateDD}
            handleDateDD={handleDateDD}
            selectAspectRatio={selectAspectRatio}
            handleSelectAspectRatio={handleSelectAspectRatio}
            inputAspectRatio={inputAspectRatio}
            handleInputAspectRatio={handleInputAspectRatio}
            selectResolution={selectResolution}
            handleSelectResolution={handleSelectResolution}
            inputResolution={inputResolution}
            handleInputResolution={handleInputResolution}
            inputFrameNumber={inputFrameNumber}
            handleFrameNumber={handleFrameNumber}
            selectFileExtension={selectFileExtension}
            handleSelectFileExtension={handleSelectFileExtension}
            inputFileExtension={inputFileExtension}
            handleInputFileExtension={handleInputFileExtension}
          />
        </TabPanel>
        <TabPanel>
          <Panel
            formatType="nonepisodicseg"
            formatTypeName={type3}
            inputProjectTitle={inputProjectTitle}
            handleProjectTitle={handleProjectTitle}
            inputSeasonEpisode={inputSeasonEpisode}
            handleSeasonEpisode={handleSeasonEpisode}
            inputSegment={inputSegment}
            handleSegment={handleSegment}
            selectAssetType={selectAssetType}
            handleAssetType={handleAssetType}
            selectBitDepth={selectBitDepth}
            handleBitDepth={handleBitDepth}
            selectDynamicRange={selectDynamicRange}
            handleDynamicRange={handleDynamicRange}
            selectACESVer={selectACESVer}
            handleACESVer={handleACESVer}
            selectColorSpace={selectColorSpace}
            handleColorSpace={handleColorSpace}
            selectTransferFunc={selectTransferFunc}
            handleTransferFunc={handleTransferFunc}
            inputDateYear={inputDateYear}
            setInputDateYear={setInputDateYear}
            handleDateYear={handleDateYear}
            selectDateMM={selectDateMM}
            setSelectDateMM={setSelectDateMM}
            handleDateMM={handleDateMM}
            selectDateDD={selectDateDD}
            setSelectDateDD={setSelectDateDD}
            handleDateDD={handleDateDD}
            selectAspectRatio={selectAspectRatio}
            handleSelectAspectRatio={handleSelectAspectRatio}
            inputAspectRatio={inputAspectRatio}
            handleInputAspectRatio={handleInputAspectRatio}
            selectResolution={selectResolution}
            handleSelectResolution={handleSelectResolution}
            inputResolution={inputResolution}
            handleInputResolution={handleInputResolution}
            inputFrameNumber={inputFrameNumber}
            handleFrameNumber={handleFrameNumber}
            selectFileExtension={selectFileExtension}
            handleSelectFileExtension={handleSelectFileExtension}
            inputFileExtension={inputFileExtension}
            handleInputFileExtension={handleInputFileExtension}
          />
        </TabPanel>
      </Tabs>

      <div className="container-narrow">
        <Disclaimer />
      </div>

      <section className="request">
        <div className="container-narrow">
          <p>
            This website is operated by an individual. Any damage or loss based
            on the information entered on this website is the responsibility of
            the person who entered the information.
          </p>
          <p>
            I would like to make improvements to the website as requested by the
            user.
            <br />
            To request additional items or revised information, please enter
            your request below.
          </p>

          <Form />
        </div>
      </section>
    </Layout>
  )
}

export default Convention

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

export default ({
  formatTypeName,
  formatType,
  sampleShown,
  setSampleShown,
}) => {
  const typeNonEpisodicSegmented = formatType === 'nonepisodicseg'

  // Folder Name
  const typeEpisodicFolderName = (
    <>
      [Project Title]_<span className="highlight">[Season & Episode]</span>
      _[Asset Type]_[Bit Depth]_[Dynamic Range]_[ACES Version]_[Color
      Space]_[Transfer Function]_[Date]_[Aspect Ratio]_[Resolution]
    </>
  )
  const typeNonEpisodicLpFolderName =
    '[Project Title]_[Asset Type]_[Bit Depth]_[Dynamic Range]_[ACES Version]_[Color Space]_[Transfer Function]_[Date]_[Aspect Ratio]_[Resolution]'
  const typeNonEpisodicSegmentedFolderName =
    '[Project Title]_[Asset Type]_[Bit Depth]_[Dynamic Range]_[ACES Version]_[Color Space]_[Transfer Function]_[Date]_[Aspect Ratio]_[Resolution]'

  // File Name
  const typeEpisodicFileName = (
    <>
      [Project Title]_<span className="highlight">[Season & Episode]</span>
      _[Asset Type]_[Bit Depth]_[Dynamic Range]_[ACES Version]_[Color
      Space]_[Transfer Function]_[Date]_[Aspect Ratio]_[Resolution].[Frame
      Number].[File Extension]
    </>
  )
  const typeNonEpisodicLpFileName = (
    <>
      [Project Title]_<span className="highlight">[Segment]</span>
      _[Asset Type]_[Bit Depth]_[Dynamic Range]_[ACES Version]_[Color
      Space]_[Transfer Function]_[Date]_[Aspect Ratio]_[Resolution].[Frame
      Number].[File Extension]
    </>
  )
  const typeNonEpisodicSegmentedFileName = (
    <>
      [Project Title]_<span className="highlight">[Segment]</span>
      _[Asset Type]_[Bit Depth]_[Dynamic Range]_[ACES Version]_[Color
      Space]_[Transfer Function]_[Date]_[Aspect Ratio]_[Resolution].[Frame
      Number].[File Extension]
    </>
  )

  // Output Folder Name
  const typeEpisodicFolderNameOutput =
    'episodictitle_s01e101_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160'
  const typeNonEpisodicLpFolderNameOutput =
    'nonepisodiclongtitle_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160'
  const typeNonEpisodicSegmentedFolderNameOutput =
    'nonepisodicsegmentedtitle_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160'

  // Output File Name
  const typeEpisodicFileNameOutput = (
    <>
      episodictitle_s01e101_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160
      <span className="color-txt">.#######.tif</span>
    </>
  )
  const typeNonEpisodicLpFileNameOutput = (
    <>
      nonepisodiclongtitle_lp_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160
      <span className="color-txt">.#######.tif</span>
    </>
  )
  const typeNonEpisodicSegmentedFileNameOutput = (
    <>
      nonepisodicsegmentedtitle_r01_vdm_16b_hdr_p3d65_pq_20190102_200_3840x2160
      <span className="color-txt">.#######.tif</span>
    </>
  )

  const data = useStaticQuery(graphql`
    query {
      sample1: file(relativePath: { eq: "img-sample1.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 900) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample2: file(relativePath: { eq: "img-sample2.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 900) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample3: file(relativePath: { eq: "img-sample3.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 900) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <section className="sample">
        <div className="container">
          {!sampleShown && (
            <button
              aria-label="See Samples"
              className="sample__button"
              onClick={() => setSampleShown(!sampleShown)}
            >
              See Sample <span className="arrow-down"></span>
            </button>
          )}
          <div className={`sample__content ${sampleShown ? 'is-active' : ''}`}>
            <h3 className="sample__content_head">{formatTypeName}</h3>
            <ul className="sample__content__lists">
              <li className="sample__content__list">
                <div className="list__head">Folder Name:</div>
                <div className="list__content">
                  {(() => {
                    switch (formatType) {
                      case 'episodic':
                        return typeEpisodicFolderName
                      case 'nonepisodiclp':
                        return typeNonEpisodicLpFolderName
                      case 'nonepisodicseg':
                        return typeNonEpisodicSegmentedFolderName
                      default:
                        return typeEpisodicFolderName
                    }
                  })()}
                </div>
              </li>
              {typeNonEpisodicSegmented && (
                <li className="sample__content__list">
                  <div className="list__head">Sub-Folder Name:</div>
                  <div className="list__content">
                    <span className="highlight">[Segment]</span>
                  </div>
                </li>
              )}
              <li className="sample__content__list">
                <div className="list__head">File Name:</div>
                <div className="list__content">
                  {(() => {
                    switch (formatType) {
                      case 'episodic':
                        return typeEpisodicFileName
                      case 'nonepisodiclp':
                        return typeNonEpisodicLpFileName
                      case 'nonepisodicseg':
                        return typeNonEpisodicSegmentedFileName
                      default:
                        return typeEpisodicFileName
                    }
                  })()}
                </div>
              </li>
              <li className="sample__content__list sample__content__list--output">
                <div className="list__head">Generator Output</div>
                <div className="list__content">
                  <div>
                    <div className="list__content__head">Folder Name</div>
                    <div className="list__content__value">
                      {(() => {
                        switch (formatType) {
                          case 'episodic':
                            return typeEpisodicFolderNameOutput
                          case 'nonepisodiclp':
                            return typeNonEpisodicLpFolderNameOutput
                          case 'nonepisodicseg':
                            return typeNonEpisodicSegmentedFolderNameOutput
                          default:
                            return typeEpisodicFolderNameOutput
                        }
                      })()}
                    </div>
                  </div>
                  {typeNonEpisodicSegmented && (
                    <div>
                      <div className="list__content__head">Sub-Folder Name</div>
                      <div className="list__content__value">r01</div>
                    </div>
                  )}
                  <div>
                    <div className="list__content__head">File Name</div>
                    <div className="list__content__value">
                      {(() => {
                        switch (formatType) {
                          case 'episodic':
                            return typeEpisodicFileNameOutput
                          case 'nonepisodiclp':
                            return typeNonEpisodicLpFileNameOutput
                          case 'nonepisodicseg':
                            return typeNonEpisodicSegmentedFileNameOutput
                          default:
                            return typeEpisodicFileNameOutput
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            {(() => {
              switch (formatType) {
                case 'episodic':
                  return (
                    <Img
                      fluid={data.sample1.childImageSharp.fluid}
                      alt="Sample"
                      className="sample__img"
                    />
                  )
                case 'nonepisodiclp':
                  return (
                    <Img
                      fluid={data.sample2.childImageSharp.fluid}
                      alt="Sample"
                      className="sample__img"
                    />
                  )
                case 'nonepisodicseg':
                  return (
                    <Img
                      fluid={data.sample3.childImageSharp.fluid}
                      alt="Sample"
                      className="sample__img"
                    />
                  )
                default:
                  return (
                    <Img
                      fluid={data.sample1.childImageSharp.fluid}
                      alt="Sample"
                      className="sample__img"
                    />
                  )
              }
            })()}
          </div>
        </div>
      </section>
    </>
  )
}

export const MonthList = [
  { value: '01', name: '1' },
  { value: '02', name: '2' },
  { value: '03', name: '3' },
  { value: '04', name: '4' },
  { value: '05', name: '5' },
  { value: '06', name: '6' },
  { value: '07', name: '7' },
  { value: '08', name: '8' },
  { value: '09', name: '9' },
  { value: '10', name: '10' },
  { value: '11', name: '11' },
  { value: '12', name: '12' },
]

export const DateList = [
  { value: '01', name: '1' },
  { value: '02', name: '2' },
  { value: '03', name: '3' },
  { value: '04', name: '4' },
  { value: '05', name: '5' },
  { value: '06', name: '6' },
  { value: '07', name: '7' },
  { value: '08', name: '8' },
  { value: '09', name: '9' },
  { value: '10', name: '10' },
  { value: '11', name: '11' },
  { value: '12', name: '12' },
  { value: '13', name: '13' },
  { value: '14', name: '14' },
  { value: '15', name: '15' },
  { value: '16', name: '16' },
  { value: '17', name: '17' },
  { value: '18', name: '18' },
  { value: '19', name: '19' },
  { value: '20', name: '20' },
  { value: '21', name: '21' },
  { value: '22', name: '22' },
  { value: '23', name: '23' },
  { value: '24', name: '24' },
  { value: '25', name: '25' },
  { value: '26', name: '26' },
  { value: '27', name: '27' },
  { value: '28', name: '28' },
  { value: '29', name: '29' },
  { value: '30', name: '30' },
  { value: '31', name: '31' },
]

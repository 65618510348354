import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'

import Samples from './Samples'
import Result from './Result'
import Caption from './Caption'
import {
  projectTitleCaption,
  EpisodeCaption,
  NonEpisodeCaption,
  AssetTypeCaption,
  BitDepthCaption,
  DynamicRangeCaption,
  ACESVerCaption,
  ColorSpaceCaption,
  TransferFunctionCaption,
  AspectRatioCaption,
  ResolutionCaption,
  FrameNumberCaption,
  FileExtensionCaption,
  DateCaption,
} from '../utils/CaptionTextList'
import AssetType from '../utils/AssetType'
import AspectRatio from '../utils/AspectRatio'
import Resolution from '../utils/Resolution'
import ColorSpace from '../utils/ColorSpace'
import BitDepth from '../utils/BitDepth'
import { MonthList, DateList } from '../utils/DateValue'
import DynamicRange from '../utils/DynamicRange'
import TransferFunction from '../utils/TransferFunction'
import Extension from '../utils/Extension'

const Panel = props => {

  // Valuable for checking tab state
  const typeEpisodic = props.formatType === 'episodic'

  // Sample State
  const [sampleShown, setSampleShown] = useState(false)

  // Result State
  const [finalResultFolder, setFinalResultFolder] = useState('')
  const [finalResultFile, setFinalResultFile] = useState('')

  // Today function
  function getCurrentDate() {
    const selectMonth = document.querySelector(
      '#select-date-mm'
    ) as HTMLSelectElement
    const selectDate = document.querySelector(
      '#select-date-dd'
    ) as HTMLSelectElement

    const today = new Date()
    const year = today.getFullYear()

    const month: any = format(today, 'MM')
    const date: any = format(today, 'dd')

    props.setInputDateYear(year)
    props.setSelectDateMM(month)
    props.setSelectDateDD(date)

    selectMonth.selectedIndex = month
    selectDate.selectedIndex = date
  }

  useEffect(() => {
    // Get Result Value
    const ProjectTitle = props.inputProjectTitle
    const SeasonEpisodeSegment = typeEpisodic
      ? props.inputSeasonEpisode
        ? '_' + props.inputSeasonEpisode
        : ''
      : props.inputSegment
      ? '_' + props.inputSegment
      : ''
    const AssetType = props.selectAssetType ? '_' + props.selectAssetType : ''
    const BitDepth = props.selectBitDepth ? '_' + props.selectBitDepth : ''
    const DynamicRange = props.selectDynamicRange
      ? '_' + props.selectDynamicRange
      : ''
    const ACESVer = props.selectACESVer ? '_' + props.selectACESVer : ''
    const ColorSpace = props.selectColorSpace
      ? '_' + props.selectColorSpace
      : ''
    const TransferFunc = props.selectTransferFunc
      ? '_' + props.selectTransferFunc
      : ''
    const DateYear = props.inputDateYear ? '_' + props.inputDateYear : ''
    const DateMM = props.selectDateMM
    const DateDD = props.selectDateDD
    const AspectRatio = props.selectAspectRatio
      ? '_' + props.selectAspectRatio
      : '' || props.inputAspectRatio
      ? '_' + props.inputAspectRatio
      : ''
    const Resolution = props.selectResolution
      ? '_' + props.selectResolution
      : '' || props.inputResolution
      ? '_' + props.inputResolution
      : ''
    const FrameNumber = props.inputFrameNumber
      ? '.' + props.inputFrameNumber
      : ''
    const FileExtension = props.selectFileExtension
      ? '.' + props.selectFileExtension
      : '' || props.inputFileExtension
      ? '.' + props.inputFileExtension
      : ''

    const MargeForFolder =
      ProjectTitle +
      SeasonEpisodeSegment +
      AssetType +
      BitDepth +
      DynamicRange +
      ACESVer +
      ColorSpace +
      TransferFunc +
      DateYear +
      DateMM +
      DateDD +
      AspectRatio +
      Resolution
    setFinalResultFolder(MargeForFolder)

    const MargeForFile =
      ProjectTitle +
      SeasonEpisodeSegment +
      AssetType +
      BitDepth +
      DynamicRange +
      ACESVer +
      ColorSpace +
      TransferFunc +
      DateYear +
      DateMM +
      DateDD +
      AspectRatio +
      Resolution +
      FrameNumber +
      FileExtension
    setFinalResultFile(MargeForFile)
  })

  return (
    <>
      <Samples
        formatTypeName={props.formatTypeName}
        formatType={props.formatType}
        sampleShown={sampleShown}
        setSampleShown={setSampleShown}
      />

      <Result
        formatType={props.formatType}
        finalResultFolder={finalResultFolder}
        finalResultFile={finalResultFile}
        inputSegment={props.inputSegment}
      />

      <section className="create-area container-narrow">
        <div className="item item-input">
          <h3 className="item__head">
            Project title:
            <Caption captionClass="pos-top" captionText={projectTitleCaption} />
          </h3>
          <div className="item__content">
            <input
              className={`full ${props.inputProjectTitle && 'filled'}`}
              type="text"
              placeholder="i.e. zombie_apocalypse"
              value={props.inputProjectTitle}
              onChange={props.handleProjectTitle}
            />
          </div>
        </div>

        <div className="item item-select">
          <h3 className="item__head">
            {typeEpisodic ? 'Season & Episode:' : 'Segment:'}
            <Caption
              captionClass="pos-top"
              captionText={typeEpisodic ? EpisodeCaption : NonEpisodeCaption}
            />
          </h3>
          <div className="item__content">
            {typeEpisodic ? (
              <input
                className={`full ${props.inputSeasonEpisode && 'filled'}`}
                type="text"
                placeholder="i.e. s01e101"
                value={props.inputSeasonEpisode}
                onChange={props.handleSeasonEpisode}
              />
            ) : (
              <input
                className={`full ${props.inputSegment && 'filled'}`}
                type="text"
                placeholder={(() => {
                  switch (props.formatType) {
                    case 'nonepisodiclp':
                      return 'i.e. lp'
                    case 'nonepisodicseg':
                      return 'i.e. r01'
                    default:
                      return 'i.e. lp'
                  }
                })()}
                value={props.inputSegment}
                onChange={props.handleSegment}
              />
            )}
          </div>
        </div>

        <div className="item item-select">
          <h3 className="item__head">
            Asset Type:
            <Caption captionClass="pos-bottom" captionText={AssetTypeCaption} />
          </h3>
          <div className="item__content">
            <select
              id="select-asset-type"
              className={`full ${props.selectAssetType && 'filled'}`}
              defaultValue={props.selectAssetType}
              onChange={props.handleAssetType}
            >
              <option value="">Please select</option>
              {(() => {
                return AssetType.map((ut, i) => {
                  return (
                    <option key={i} value={ut.value}>
                      {ut.name}
                    </option>
                  )
                })
              })()}
            </select>
          </div>
        </div>

        <div className="item item-select">
          <h3 className="item__head">
            Bit Depth:
            <Caption captionClass="pos-top" captionText={BitDepthCaption} />
          </h3>
          <div className="item__content">
            <select
              id="select-bit-depth"
              className={`full ${props.selectBitDepth && 'filled'}`}
              defaultValue={props.selectBitDepth}
              onChange={props.handleBitDepth}
            >
              <option value="">Please select</option>
              {BitDepth.map((ut, i) => {
                return (
                  <option key={i} value={ut.value}>
                    {ut.name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="item item-select">
          <h3 className="item__head">
            Dynamic Range:
            <Caption captionClass="pos-top" captionText={DynamicRangeCaption} />
          </h3>
          <div className="item__content">
            <select
              id="select-dynamic-range"
              className={`full ${props.selectDynamicRange && 'filled'}`}
              defaultValue={props.selectDynamicRange}
              onChange={props.handleDynamicRange}
            >
              <option value="">Please select</option>
              {DynamicRange.map((ut, i) => {
                return (
                  <option key={i} value={ut.value}>
                    {ut.name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="item item-select">
          <h3 className="item__head">
            ACES Version:
            <Caption captionClass="pos-top" captionText={ACESVerCaption} />
          </h3>
          <div className="item__content">
            <select
              id="select-"
              className={`full ${props.selectACESVer && 'filled'}`}
              defaultValue={props.selectACESVer}
              onChange={props.handleACESVer}
            >
              <option value="">Please select</option>
              <option value="aces103">aces103</option>
              <option value="aces110">aces110</option>
            </select>
          </div>
        </div>

        <div className="item item-select">
          <h3 className="item__head">
            Color Space:
            <Caption captionClass="pos-top" captionText={ColorSpaceCaption} />
          </h3>
          <div className="item__content">
            <select
              id="select-color-space"
              className={`full ${props.selectColorSpace && 'filled'}`}
              defaultValue={props.selectColorSpace}
              onChange={props.handleColorSpace}
            >
              <option value="">Please select</option>
              {ColorSpace.map((ut, i) => {
                return (
                  <option key={i} value={ut.value}>
                    {ut.name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="item item-select">
          <h3 className="item__head">
            Transfer Function:
            <Caption
              captionClass="pos-top"
              captionText={TransferFunctionCaption}
            />
          </h3>
          <div className="item__content">
            <select
              id="select-transfer-function"
              className={`full ${props.selectTransferFunc && 'filled'}`}
              defaultValue={props.selectTransferFunc}
              onChange={props.handleTransferFunc}
            >
              <option value="">Please select</option>
              {TransferFunction.map((ut, i) => {
                return (
                  <option key={i} value={ut.value}>
                    {ut.name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="item item-select">
          <h3 className="item__head">
            Date:
            <Caption captionClass="pos-top" captionText={DateCaption} />
          </h3>
          <div className="item__content">
            <button className="button-today" onClick={getCurrentDate}>
              Today
            </button>

            <input
              id="input-date-year"
              className={`width-s ${props.inputDateYear && 'filled'}`}
              type="text"
              placeholder="Year"
              value={props.inputDateYear}
              onChange={props.handleDateYear}
            />
            <select
              id="select-date-mm"
              className={`width-s mar-l-10 ${props.selectDateMM && 'filled'}`}
              defaultValue={props.selectDateMM}
              onChange={props.handleDateMM}
            >
              <option value="">MM</option>
              {MonthList.map((ut, i) => {
                return (
                  <option key={i} value={ut.value}>
                    {ut.name}
                  </option>
                )
              })}
            </select>
            <select
              id="select-date-dd"
              className={`width-s mar-l-10 ${props.selectDateDD && 'filled'}`}
              defaultValue={props.selectDateDD}
              onChange={props.handleDateDD}
            >
              <option value="">DD</option>
              {DateList.map((ut, i) => {
                return (
                  <option key={i} value={ut.value}>
                    {ut.name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="item item-select">
          <h3 className="item__head">
            Aspect Ratio:
            <Caption captionClass="pos-top" captionText={AspectRatioCaption} />
          </h3>
          <div className="item__content flex-column">
            <select
              id="select-aspect-ratio"
              className={`width-l ${props.selectAspectRatio && 'filled'}`}
              defaultValue={props.selectAspectRatio}
              onChange={props.handleSelectAspectRatio}
              disabled={props.inputAspectRatio && true}
            >
              <option value="">Please select</option>
              {(() => {
                return AspectRatio.map((ut, i) => {
                  return (
                    <option key={i} value={ut.value}>
                      {ut.name}
                    </option>
                  )
                })
              })()}
            </select>
            <input
              id="input-aspect-ratio"
              className={`width-m ${props.inputAspectRatio && 'filled'}`}
              type="text"
              placeholder="Custom code"
              value={props.inputAspectRatio}
              onChange={props.handleInputAspectRatio}
              disabled={props.selectAspectRatio && true}
            />
          </div>
        </div>

        <div className="item item-select">
          <h3 className="item__head">
            Resolution:
            <Caption captionClass="pos-top" captionText={ResolutionCaption} />
          </h3>
          <div className="item__content flex-column">
            <select
              id="select-resolution"
              className={`width-l ${props.selectResolution && 'filled'}`}
              defaultValue={props.selectResolution}
              onChange={props.handleSelectResolution}
              disabled={props.inputResolution && true}
            >
              <option value="">Please select</option>
              {Resolution.map((ut, i) => {
                return (
                  <option key={i} value={ut.value}>
                    {ut.name}
                  </option>
                )
              })}
            </select>
            <input
              id="input-resolution"
              className={`width-m ${props.inputResolution && 'filled'}`}
              type="text"
              placeholder="Custom code"
              value={props.inputResolution}
              onChange={props.handleInputResolution}
              disabled={props.selectResolution && true}
            />
          </div>
        </div>

        <div className="item__option">
          <p className="item__option__note">
            The following are optional. Use these if you want to name the files
            individually. The results are displayed in "All name."
          </p>
          <div className="item__option__inner">
            <div className="item item-input">
              <h3 className="item__head">
                Frame Number:
                <Caption
                  captionClass="pos-top"
                  captionText={FrameNumberCaption}
                />
              </h3>
              <div className="item__content">
                <input
                  className={`full ${props.inputFrameNumber && 'filled'}`}
                  type="text"
                  placeholder="i.e. #######"
                  value={props.inputFrameNumber}
                  onChange={props.handleFrameNumber}
                />
              </div>
            </div>

            <div className="item item-input">
              <h3 className="item__head">
                File Extension:
                <Caption
                  captionClass="pos-top"
                  captionText={FileExtensionCaption}
                />
              </h3>
              <div className="item__content flex-column">
                <select
                  id="select-file-extension"
                  className={`width-l ${props.selectFileExtension && 'filled'}`}
                  defaultValue={props.selectFileExtension}
                  onChange={props.handleSelectFileExtension}
                  disabled={props.inputFileExtension && true}
                >
                  <option value="">Please select</option>
                  {Extension.map((ut, i) => {
                    return (
                      <option key={i} value={ut.value}>
                        {ut.name}
                      </option>
                    )
                  })}
                </select>
                <input
                  id="input-file-extension"
                  className="width-m"
                  type="text"
                  placeholder="Custom code"
                  value={props.inputFileExtension}
                  onChange={props.handleInputFileExtension}
                  disabled={props.selectFileExtension && true}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Panel

const AssetTypeList = [
  { value: 'nam', name: 'Non-Graded Archival Master' },
  { value: 'nam-txtls', name: 'Non-Graded Archival Master:Textless Inserts' },
  { value: 'gam', name: 'Graded Archival Master' },
  { value: 'vdm', name: 'Video Display Master' },
  { value: 'dcdm', name: 'Digital Cinema Delivery Master' },
  {
    value: 'dcdm-txtls',
    name: 'Digital Cinema Distribution Master: Textless Inserts',
  },
]

export default AssetTypeList

const ExtenstionList = [
  { value: 'mov', name: 'mov' },
  { value: 'dpx', name: 'dpx' },
  { value: 'exr', name: 'exr' },
  { value: 'tga', name: 'tga' },
  { value: 'tif', name: 'tif' },
  { value: 'tiff', name: 'tiff' },
  { value: 'imp', name: 'imp' },
  { value: 'png', name: 'png' },
  { value: 'aaf', name: 'aaf' },
  { value: 'xml', name: 'xml' },
  { value: 'edl', name: 'edl' },
  { value: 'wav', name: 'wav' },
  { value: 'aif', name: 'aif' },
  { value: 'als', name: 'als' },
  { value: 'cin', name: 'cin' },
  { value: 'jpg', name: 'jpg' },
  { value: 'iff', name: 'iff' },
  { value: 'pict', name: 'pict' },
  { value: 'picio', name: 'picio' },
  { value: 'sgi', name: 'sgi' },
  { value: 'pic', name: 'pic' },
  { value: 'rla', name: 'rla' },
]

export default ExtenstionList
